<template>
	<el-dialog title="方案设计思路" :visible.sync="show" :before-close="close" class="choiceMajor" width="40%" :modal="false">
		<el-input type="textarea" v-model="text" :rows="10"></el-input>
		<span slot="footer" class="dialog-footer">
			<el-button @click="cancel" size="small">取 消</el-button>
			<el-button type="primary" @click="submit" size="small">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				text: '',
			}
		},
		props: {
			designShow: Boolean,
		},
		methods: {
			submit() {
				console.log('提交');
				this.$emit('closeDesign', false);
				this.show = false;
			},
			close() {
				this.$emit('closeDesign', false);
			},
			cancel(){
				this.$emit('closeDesign', false);
				this.show = false;
			}
		},
		watch: {
			designShow(nv) {
				this.show = nv;
			}
		}
	}
</script>

<style lang="scss" scoped="">
</style>
